import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulInfo {
      siteDesc {
        siteDesc
      }
      siteLogo {
        svg {
          content
        }
      }
    }
  }
`
const Logo = ({ desc }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="block logo">
      <h1>
        <Link to="/">
          <div
            className="logo icon"
            dangerouslySetInnerHTML={{ __html: data.c.siteLogo.svg.content }}
          />
        </Link>
      </h1>
      {desc ? <p>{data.c.siteDesc.siteDesc}</p> : ""}
    </div>
  )
}

export default Logo
