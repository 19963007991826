import React from "react"

//component
import Mobile from "../components/block/Mobile"
import Branding from "../components/zone/Branding"
import TitleHome from "../components/zone/TitleHome"
import TitlePage from "../components/zone/TitlePage"

//scss
import "../scss/zone/header.scss"

const Header = ({ location, pageContext }) => {
  return (
    <div className="header">
      <Branding />
      {location.pathname === "/" ? (
        <TitleHome />
      ) : (
        <TitlePage location={location} pageContext={pageContext} />
      )}
      <Mobile />
    </div>
  )
}

export default Header
