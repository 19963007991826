import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//data
const getData = graphql`
  {
    c: contentfulInfo {
      tva1title
      tva1subtitle
      tva1icon {
        svg {
          content
        }
      }
    }
  }
`
const Tva1 = ({ icon, title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='block tvaOne'>
      {icon ? (
        <div
          className='icon'
          dangerouslySetInnerHTML={{ __html: data.c.tva1icon.svg.content }}
        />
      ) : (
        ''
      )}
      <div className='content'>
        {title ? <h3>{data.c.tva1title}</h3> : ''}
        <p>{data.c.tva1subtitle}</p>
      </div>
    </div>
  )
}

export default Tva1
