import React, { useState } from 'react'
//components
import MenuMain from '../menu/MenuMain'
import Logo from '../block/Logo'
import Phone1 from '../block/Phone1'
//scss
import '../../scss/block/mobile.scss'
const Mobile = () => {
  const [mobile, setMobile] = useState(0)

  const changeMobile = () => {
    setMobile((mobile) => !mobile)
  }

  return (
    <>
      <button
        className={`mobileBlock ${mobile ? 'mobileBlockActive' : ''}`}
        onClick={() => changeMobile()}
      >
        <div className='mobileBlockContainer'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={`mobileMenu ${mobile ? 'mobileMenuActive' : ''}`}>
        <div className='mobileMenuContainer'>
          <Logo />
          <MenuMain />
          <Phone1 />
        </div>
      </div>
    </>
  )
}

export default Mobile
