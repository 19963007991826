import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulInfo {
      phone1link
      phone1title
      phone1subtitle
      phone1icon {
        svg {
          content
        }
      }
    }
  }
`
const Phone1 = ({ icon, title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="block phoneOne">
      {icon ? (
        <div
          className="icon"
          dangerouslySetInnerHTML={{ __html: data.c.phone1icon.svg.content }}
        />
      ) : (
        ""
      )}
      <div className="content">
        {title ? <h3>{data.c.phone1title}</h3> : ""}
        <a href={data.c.phone1link}>{data.c.phone1subtitle}</a>
      </div>
    </div>
  )
}

export default Phone1
