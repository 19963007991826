import React from 'react'
//components
import Phone1 from '../block/Phone1'
import Adrs1 from '../block/Adrs1'
import Tva1 from '../block/Tva1'
//scss
import '../../scss/zone/info.scss'
const Info = () => {
  return (
    <div className='zone info'>
      <div className='container flex'>
        <Adrs1 icon={true} title={true} />
        <Phone1 icon={true} title={true} />
        <Tva1 icon={true} title={true} />
      </div>
    </div>
  )
}

export default Info
