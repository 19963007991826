import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
//component
import Logo from '../block/Logo'
import MenuMain from '../menu/MenuMain'
import Phone1 from '../block/Phone1'

//scss
import '../../scss/zone/branding.scss'

//data
const getData = graphql`
  {
    e: file(name: { eq: "bgLight" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Branding = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='zone branding'>
      <BackgroundImage fluid={data.e.childImageSharp.fluid} className='bg'>
        <div className='container flex'>
          <Logo />
          <MenuMain />
          <Phone1 />
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Branding
