import React from 'react'
//component
import ContactBlock from '../block/ContactBlock'
import Phone1 from '../block/Phone1'
import Mail1 from '../block/Mail1'

//scss
import '../../scss/zone/contact.scss'
const Contact = () => {
  return (
    <div className='zone contact'>
      <div className='container flex'>
        <ContactBlock />
        <div className='contactLink'>
          <Phone1 icon={true} title={true} />
          <Mail1 icon={true} title={true} />
        </div>
      </div>
    </div>
  )
}

export default Contact
