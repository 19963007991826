import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulConfig {
      contactBlockTitle
      contactBlockDesc {
        contactBlockDesc
      }
    }
  }
`
const ContactBlock = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="block contactBlock">
      <h2>{data.c.contactBlockTitle}</h2>
      <p>{data.c.contactBlockDesc.contactBlockDesc}</p>
    </div>
  )
}

export default ContactBlock
