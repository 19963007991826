import React from 'react'
//component
import Transition from '../transitions/Transition'
import Header from '../components/Header'
import Info from '../components/zone/Info'
import ServiceOther from '../components/zone/ServiceOther'
import Contact from '../components/zone/Contact'
import Argument from '../components/zone/Argument'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
//scss
import '../scss/reset.scss'
import '../scss/variables.scss'
import '../scss/transition.scss'
import '../scss/global.scss'
import '../scss/gird.scss'
import '../scss/mep.scss'

const Layout = ({ children, location, pageContext }) => {
  if (location.pathname === '/') {
    pageContext.modele = 'home'
    pageContext.type = 'home'
    pageContext.title = 'Accueil'
  } else if (!pageContext) {
    pageContext.modele = 'other'
    pageContext.type = 'other'
  }

  return (
    <>
      <SEO pageContext={pageContext} />
      <Transition location={location}>
        <div
          className={`layout ${pageContext.modele}-modele ${pageContext.type}-type`}
        >
          <Header location={location} pageContext={pageContext} />
          {location.pathname === '/contact' ? <Info /> : ''}
          <div className='mainContent'>{children}</div>
          {pageContext.type === 'service' ||
          pageContext.type === 'serviceSub' ? (
            <ServiceOther pageContext={pageContext} />
          ) : (
            ''
          )}
          {location.pathname === '/contact' ? '' : <Contact />}
          <Argument />
          <Footer />
        </div>
      </Transition>
    </>
  )
}

export default Layout
