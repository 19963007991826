import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
//data
const getData = graphql`
  {
    c: contentfulInfo {
      siteName
    }
  }
`
const SEO = ({ pageContext }) => {
  const data = useStaticQuery(getData)

  return (
    <>
      <Helmet
        title={
          pageContext.title
            ? `${data.c.siteName} | ${pageContext.title}`
            : data.c.siteName
        }
      />
    </>
  )
}

export default SEO
