import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulConfig {
      menuLegalTitle
      menuLegal {
        title
        slug
      }
    }
  }
`

const MenuLegal = ({ title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="block menuMain">
      {title ? <h2>{data.c.menuLegalTitle}</h2> : ""}
      <ul>
        {data.c.menuLegal.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={`/${item.slug === "/" ? "" : item.slug}`}
                activeClassName="is-active"
                partiallyActive={item.slug === "/" ? false : true}
              >
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuLegal
