import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//data
const getData = graphql`
  {
    c: contentfulInfo {
      horaireTitle
      horaire {
        horaire
      }
    }
  }
`
const Horaire = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='block horaire'>
      <h2>{data.c.horaireTitle}</h2>
      <p>{data.c.horaire.horaire}</p>
    </div>
  )
}

export default Horaire
