import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulInfo {
      mail1link
      mail1title
      mail1subtitle
      mail1icon {
        svg {
          content
        }
      }
    }
  }
`
const Mail1 = ({ icon, title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="block mailOne">
      {icon ? (
        <div
          className="icon"
          dangerouslySetInnerHTML={{ __html: data.c.mail1icon.svg.content }}
        />
      ) : (
        ""
      )}
      <div className="content">
        {title ? <h3>{data.c.mail1title}</h3> : ""}
        <Link to={data.c.mail1link}>{data.c.mail1subtitle}</Link>
      </div>
    </div>
  )
}

export default Mail1
