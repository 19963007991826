import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

//component
import ArgList from '../block/ArgList'
//scss
import '../../scss/zone/argument.scss'
//data
const getData = graphql`
  {
    e: file(name: { eq: "bgDark" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Argument = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='zone argument'>
      <BackgroundImage fluid={data.e.childImageSharp.fluid} className='bg'>
        <div className='container'>
          <ArgList />
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Argument
