import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulConfig {
      menuMainTitle
      menuMain {
        ... on ContentfulPage {
          slug
          title
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        ... on ContentfulService {
          slug
          title
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`
const MenuMain = ({ title }) => {
  const data = useStaticQuery(getData)

  return (
    <div className="block menuMain">
      {title ? <h2>{data.c.menuMainTitle}</h2> : ""}
      <ul>
        {data.c.menuMain.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={`/${item.slug === "/" ? "" : item.slug}`}
                activeClassName="is-active"
                partiallyActive={item.slug === "/" ? false : true}
              >
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuMain
