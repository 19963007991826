import React from "react"
import { Link } from "gatsby"
//component
import GetSvg from "../content/GetSvg"

const Breadcrumb = ({ location, pageContext }) => {
  let t = location.pathname.split("/")
  t.splice(0, 1)
  let n = []
  const r = t.length

  for (let i = 0; i < r; i++) {
    if (i === 0) {
      n.push("/" + t[i])
    } else {
      let f = n[i - 1] + "/" + t[i]
      n.push(f)
    }
  }

  return (
    <div className=" block breadcrumb flex">
      <GetSvg id={pageContext.icon} />

      <ul>
        <li>
          <Link to="/">Accueil</Link>
        </li>
        {n.map((item, index) => {
          return (
            <li key={index}>
              <Link to={n[index]} activeClassName="is-active">
                {t[index].split("-").join(" ")}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Breadcrumb
