import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//data
const getData = graphql`
  {
    c: allContentfulAsset {
      nodes {
        contentful_id
        svg {
          content
        }
      }
    }
    d: contentfulConfig {
      defaultIcon {
        svg {
          content
        }
      }
    }
  }
`
const GetImage = ({ id }) => {
  const data = useStaticQuery(getData)
  let img = ''
  if (data.c.nodes.filter((i) => i.contentful_id === id).length !== 1) {
    img = data.d.defaultIcon
  } else {
    img = data.c.nodes.filter((i) => i.contentful_id === id)[0]
  }

  return (
    <>
      <div
        className='icon'
        dangerouslySetInnerHTML={{ __html: img.svg.content }}
      />
    </>
  )
}

export default GetImage
