import React, { useState } from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
//component
//scss
import '../../scss/zone/titleHome.scss'
//data
const getData = graphql`
  {
    e: file(name: { eq: "bgLight" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    car: file(name: { eq: "car" }) {
      childImageSharp {
        color: fixed(width: 738, height: 628) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    diag: file(name: { eq: "diag" }) {
      childImageSharp {
        color: fixed(width: 738, height: 628) {
          ...GatsbyImageSharpFixed
        }
        gray: fixed(width: 738, height: 628, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    calc: file(name: { eq: "calc" }) {
      childImageSharp {
        color: fixed(width: 738, height: 628) {
          ...GatsbyImageSharpFixed
        }
        gray: fixed(width: 738, height: 628, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prog: file(name: { eq: "prog" }) {
      childImageSharp {
        color: fixed(width: 738, height: 628) {
          ...GatsbyImageSharpFixed
        }
        gray: fixed(width: 738, height: 628, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chip: file(name: { eq: "chip" }) {
      childImageSharp {
        color: fixed(width: 738, height: 628) {
          ...GatsbyImageSharpFixed
        }
        gray: fixed(width: 738, height: 628, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    c: allContentfulService(filter: {}, sort: { fields: order, order: ASC }) {
      nodes {
        slug
        title
        image {
          fixed {
            ...GatsbyContentfulFixed
          }
        }
        resume {
          resume
        }
      }
    }
  }
`
const TitleHome = () => {
  const data = useStaticQuery(getData)
  const [diag, setDiag] = useState(0)
  const [calc, setCalc] = useState(0)
  const [prog, setProg] = useState(0)
  const [chip, setChip] = useState(0)
  return (
    <>
      <div className='zone titleHome'>
        <BackgroundImage fluid={data.e.childImageSharp.fluid} className='bg'>
          <div className='image'>
            <Image fixed={data.car.childImageSharp.color} className='main' />
            <Image
              fixed={
                diag
                  ? data.diag.childImageSharp.color
                  : data.diag.childImageSharp.gray
              }
              className={`diag ${diag ? 'active' : 'inactive'}`}
            />
            <Image
              fixed={
                calc
                  ? data.calc.childImageSharp.color
                  : data.calc.childImageSharp.gray
              }
              className={`calc ${calc ? 'active' : 'inactive'}`}
            />
            <Image
              fixed={
                prog
                  ? data.prog.childImageSharp.color
                  : data.prog.childImageSharp.gray
              }
              className={`prog ${prog ? 'active' : 'inactive'}`}
            />
            <Image
              fixed={
                chip
                  ? data.chip.childImageSharp.color
                  : data.chip.childImageSharp.gray
              }
              className={`chip ${chip ? 'active' : 'inactive'}`}
            />
            <div className='control'>
              <button
                className='diag'
                onMouseOver={() => setDiag((diag) => 1)}
                onFocus={() => setDiag((diag) => 1)}
                onMouseLeave={() => setDiag((diag) => 0)}
                onClick={() => navigate(`/${data.c.nodes[0].slug}`)}
              >
                1
              </button>
              <button
                className='calc'
                onMouseOver={() => setCalc((calc) => 1)}
                onFocus={() => setCalc((calc) => 1)}
                onMouseLeave={() => setCalc((calc) => 0)}
                onClick={() => navigate(`/${data.c.nodes[1].slug}`)}
              >
                2
              </button>
              <button
                className='prog'
                onMouseOver={() => setProg((prog) => 1)}
                onFocus={() => setProg((prog) => 1)}
                onMouseLeave={() => setProg((prog) => 0)}
                onClick={() => navigate(`/${data.c.nodes[2].slug}`)}
              >
                3
              </button>
              <button
                className='chip'
                onMouseOver={() => setChip((chip) => 1)}
                onFocus={() => setChip((chip) => 1)}
                onMouseLeave={() => setChip((chip) => 0)}
                onClick={() => navigate(`/${data.c.nodes[3].slug}`)}
              >
                4
              </button>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <div className='zone serviceHome'>
        <div className='container flex'>
          {data.c.nodes.map((item, index) => {
            return (
              <div
                key={index}
                className={`serviceElm ${
                  index === 0
                    ? diag
                      ? 'active'
                      : 'inactive'
                    : index === 1
                    ? calc
                      ? 'active'
                      : 'inactive'
                    : index === 2
                    ? prog
                      ? 'active'
                      : 'inactive'
                    : index === 3
                    ? chip
                      ? 'active'
                      : 'inactive'
                    : ''
                }`}
                onMouseOver={() =>
                  index === 0
                    ? setDiag((diag) => 1)
                    : index === 1
                    ? setCalc((calc) => 1)
                    : index === 2
                    ? setProg((prog) => 1)
                    : index === 3
                    ? setChip((chip) => 1)
                    : ''
                }
                onFocus={() =>
                  index === 0
                    ? setDiag((diag) => 1)
                    : index === 1
                    ? setCalc((calc) => 1)
                    : index === 2
                    ? setProg((prog) => 1)
                    : index === 3
                    ? setChip((chip) => 1)
                    : ''
                }
                onMouseLeave={() =>
                  index === 0
                    ? setDiag((diag) => 0)
                    : index === 1
                    ? setCalc((calc) => 0)
                    : index === 2
                    ? setProg((prog) => 0)
                    : index === 3
                    ? setChip((chip) => 0)
                    : ''
                }
                role='button'
                tabIndex={0}
              >
                <Image fixed={item.image.fixed} />
                <h2>{item.title}</h2>
                <p>{item.resume.resume}</p>
                <Link to={`/${item.slug}`}>voir</Link>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TitleHome
