import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
//scss
import '../../scss/zone/serviceOther.scss'
//data
const getData = graphql`
  {
    c: allContentfulService(filter: {}, sort: { fields: order, order: ASC }) {
      nodes {
        id
        icon {
          svg {
            content
          }
        }
        title
        resume {
          resume
        }
        slug
      }
    }
    d: contentfulConfig {
      serviceAutreTitle
    }
    e: file(name: { eq: "bgLight" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const ServiceOther = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='zone serviceOther'>
      <BackgroundImage fluid={data.e.childImageSharp.fluid} className='bg'>
        <div className='container'>
          <h2>{data.d.serviceAutreTitle}</h2>
          <div className='serviceOtherList flex'>
            {data.c.nodes
              .filter((i) => i.id !== pageContext.id)
              .map((item, index) => {
                return (
                  <div key={index} className='serviceOtherElm flex'>
                    <div
                      className='icon'
                      dangerouslySetInnerHTML={{
                        __html: item.icon.svg.content,
                      }}
                    />
                    <div className='content'>
                      <h3>{item.title}</h3>
                      <p>{item.resume.resume}</p>
                      <Link to={`/${item.slug}`}>en savoir plus</Link>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default ServiceOther
