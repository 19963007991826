import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//data
const getData = graphql`
  {
    c: allContentfulArgument(filter: {}, sort: { fields: order, order: ASC }) {
      nodes {
        title
        desc {
          desc
        }
        icon {
          svg {
            content
          }
        }
      }
    }
  }
`
const ArgList = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='block argList flex'>
      {data.c.nodes.map((item, index) => {
        return (
          <div key={index} className='argListElm'>
            <div
              className='icon'
              dangerouslySetInnerHTML={{ __html: item.icon.svg.content }}
            />
            <h2>{item.title}</h2>
            <p>{item.desc.desc}</p>
          </div>
        )
      })}
    </div>
  )
}

export default ArgList
