import React from 'react'
//components
import Logo from '../components/block/Logo'
import Horaire from '../components/block/Horaire'
import MenuMain from '../components/menu/MenuMain'
import MenuLegal from '../components/menu/MenuLegal'
//scss
import '../scss/zone/footer.scss'
const Footer = () => {
  return (
    <div className='footer'>
      <div className='container flex'>
        <div className='column'>
          <Logo desc={true} />
        </div>
        <div className='column'>
          <Horaire />
        </div>
        <div className='column'>
          <MenuMain title={true} />
        </div>
        <div className='column'>
          <MenuLegal title={true} />
        </div>
      </div>
    </div>
  )
}

export default Footer
