import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

//components
import Breadcrumb from '../block/Breadcrumb'

//scss
import '../../scss/zone/titlePage.scss'

//data
const getData = graphql`
  {
    e: file(name: { eq: "bgLight" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TitlePage = ({ location, pageContext }) => {
  const data = useStaticQuery(getData)

  return (
    <div className='zone titlePage'>
      <BackgroundImage fluid={data.e.childImageSharp.fluid} className='bg'>
        <div className='container flex'>
          <div className='titleMain'>
            <Breadcrumb location={location} pageContext={pageContext} />
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default TitlePage
